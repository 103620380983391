<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
          src="assets/ICONOS/close.svg"></ion-icon>

        <p class="tabTitle">Configuraciones</p>

        <select class="channel_select_e" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal"
          interface="popover" placeholder="Selecciona el canal">
          <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>

        <div class="out_loader" v-if="isloading">
          <div class="loader"></div>
        </div>

        <div class="config_div" v-if="!isloading">
          <h2>Ocultar secciones de la aplicación</h2>
          <ion-list>

            <ion-item :style="{ color: show_categories ? '#FFF' : '#777' }">

              <ion-icon :style="{ opacity: show_categories ? '1' : '.5' }" class="config_icon"
                src="assets/ICONOS/categoria-musical-blanco.svg" />
              Categorías musicales
              <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg"
                @click="openModal('Categorías Musicales', 'Al deshabilitar la pestaña de Categorías Musicales, ninguna de las sucursales podrá entrar a esta área.',[])" />
              <ion-toggle class="toggle_config" v-model="show_categories" aria-label="Secondary toggle"
                color="secondary" :checked="true"></ion-toggle>
            </ion-item>

            <ion-item :style="{ color: show_branches ? '#FFF' : '#777' }">

              <ion-icon :style="{ opacity: show_branches ? '1' : '.5' }" class="config_icon"
                src="assets/ICONOS/variante-blanco.svg" />
              Sucursales
              <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg"
                @click="openModal('Sucursales', 'Al deshabilitar la pestaña de Sucursales, ninguna de las sucursales podrá entrar a esta área. Los apartados involucrados son ID de Sucursal y Variantes de contenido... Sus sucursales seguirán funcionando normalmente y se podrán identificar por medio de su Link Directo.',[])" />
              <ion-toggle class="toggle_config" v-model="show_branches" aria-label="Tertiary toggle" color="tertiary"
                :checked="true"></ion-toggle>
            </ion-item>

            <ion-item :style="{ color: show_ondemand ? '#FFF' : '#777' }">

              <ion-icon :style="{ opacity: show_ondemand ? '1' : '.5' }" class="config_icon"
                src="assets/ICONOS/note-blanco.svg" />
              Audios a demanda
              <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg"
                @click="openModal('Audios a Demanda', 'Al deshabilitar la pestaña de Audios a Demanda, ninguna de las sucursales podrá entrar a esta área. Si no tiene audios a demanda agregados, recomendamos deshabilitar esta opción para quitarla del menú.',[])" />
              <ion-toggle class="toggle_config" v-model="show_ondemand" aria-label="Success toggle" color="success"
                :checked="true"></ion-toggle>
            </ion-item>

            <ion-item :style="{ color: show_links ? '#FFF' : '#777' }">

              <ion-icon :style="{ opacity: show_links ? '1' : '.5' }" class="config_icon"
                src="assets/ICONOS/links-blanco.svg" />
              Links
              <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg"
                @click="openModal('Links', 'Al deshabilitar la pestaña de Links, ninguna de las sucursales podrá entrar a esta área.',[])" />
              <ion-toggle class="toggle_config" v-model="show_links" aria-label="Warning toggle" color="warning"
                :checked="true"></ion-toggle>
            </ion-item>

            <ion-item :style="{ color: show_volume ? '#FFF' : '#777' }">

              <ion-icon :style="{ opacity: show_volume ? '1' : '.5' }" class="config_icon"
                src="assets/ICONOS/audios-blanco.svg" />
              Control del volumen
              <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg"
                @click="openModal('Control del Volumen', 'Al deshabilitar la pestaña de Control del Volumen, ninguna de las sucursales podrá ver el control de volumen del reproductor. Nota importante: El control de volumen del dispositivo seguirá funcionando normalmente independientemente de esta configuración, por lo que recomendamos tenerlo habilitado, para poder monitorear el volumen de la música en las sucursales por medio del monitor.',[])" />
              <ion-toggle class="toggle_config" v-model="show_volume" aria-label="Medium toggle" color="medium"
                :checked="true"></ion-toggle>
            </ion-item>

          </ion-list>

          <h2>Comportamiento de la aplicación</h2>
          <ion-list>

            <ion-item :style="{ color: save_m_variant ? '#FFF' : '#777' }">

              <ion-icon :style="{ opacity: save_m_variant ? '1' : '.5' }" class="config_icon"
                src="assets/ICONOS/time-blanco.svg" />
              Limite de tiempo en Categoria Musical
              <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg"
                @click="openModal('Límite de tiempo en Categoría Musical', 'Al deshabilitar el límite de tiempo en la categoría musical, los usuarios podrán escuchar una categoría musical sin límite de tiempo. Si se habilita, se podrá configurar el tiempo máximo de reproducción de una categoría musical. Recomendamos tenerlo habilitado para evitar que una categoría musical se reproduzca por mucho tiempo en una sucursal.',[])" />
              <ion-toggle class="toggle_config" v-model="save_m_variant" aria-label="Danger toggle" color="danger"
                :checked="true"></ion-toggle>
            </ion-item>

            <ion-item :style="{ color: save_m_variant ? '#FFF' : '#777' }">
              Limite de
              <ion-input :value="save_m_variant_count" @ionInput="handleInputChange" class="save_m_variant_count"
                type="number" placeholder="#" min="10" max="360" :disabled="!save_m_variant"></ion-input>
              minutos
            </ion-item>

          </ion-list>

          <button class="save_config_buton sc-btn-3" @click="saveConfig()">
            <span>
              Guardar cambios
            </span>
          </button>
        </div>

      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon class="config_icon" src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, IonToggle, modalController } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill';
import { toastController } from '@ionic/vue';
import infoModal from '../infoModal.vue';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent, IonToggle },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      clientData: null,
      token: null,
      serverDate: null,
      clientData: {},
      channel_name_list: [],
      channel_list: [],
      selectedChannel: null,
      dashboard:
      {
        client: {
          email: "",
          id: 0,
          name: "",
          obligation: 0,
          retainer: 0
        },
        radio: {
          blacklist: "",
          branches: 0,
          categories: "",
          image: "2f07f7cfc8d4348937078a566c7d943e.png",
          link: "",
          mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
          name: "",
          tags_blacklist: "",
          video_enabled: false
        },
        spots: {
          id: 0,
          level: 0,
          month: "",
          name: "",
          obligation: 0,
          percentage: "",
          produced: null,
          year: ""
        },
      },
      show_categories: true,
      show_branches: true,
      show_links: true,
      show_ondemand: true,
      show_volume: true,
      save_m_variant: true,
      save_m_variant_count: 10,
      isloading: true,
    }
  },
  methods: {
    async openModal(title, desc, list) {
      const modal = await modalController.create({
        component: infoModal,
        componentProps: {
          title: title,
          desc: desc,
          list: list,
        },
      });
      return modal.present();
    },
    handleInputChange(event) {
      this.save_m_variant_count = event.target.value;
      console.log("save_m_variant_count", event.target.value);
    },
    handleChannelChange(event) {
      this.isloading = true;
      this.selectedChannel = event.target.value;
      this.emitter.emit("selectedChannel", event.target.value);
      Storage.set({
        key: "cabina_admin_selectedChannel",
        value: this.selectedChannel,
      });
      setTimeout(() => {
        this.getConfig();
      }, 1000);
    },
    async getConfig() {
      this.isloading = true;
      try {
        console.log("Obteniendo getConfig...");
        if (this.token == null || this.token == undefined || this.token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (this.token != null && this.token != undefined && this.token != "") {
          let url = "api/get_radio_config_app";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/get_radio_config_app";
          let response = await axios.post(url, {
            token: this.token,
            channel: this.selectedChannel
          })
            .then(r => {
              console.log("get_radio_config_app", r.data);
              this.show_categories = r.data.show_categories;
              this.show_branches = r.data.show_branches;
              this.show_links = r.data.show_links;
              this.show_ondemand = r.data.show_ondemand;
              this.show_volume = r.data.show_volume;
              this.save_m_variant = r.data.save_m_variant;
              this.save_m_variant_count = r.data.smv_time;
              this.isloading = false;

            }).catch(error => {
              console.log("3Error en función validateToken de getConfig: " + error.message);
              return error;
            });
        }
      } catch (error) {
        console.log("Error en función getConfig de Conexiones: " + error.message);
      }
    },

    async saveConfig() {
      this.isloading = true;
      try {
        console.log("Obteniendo conexiones...");
        if (this.token == null || this.token == undefined || this.token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (this.token != null && this.token != undefined && this.token != "") {
          let url = "api/set_radio_config_app";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/set_radio_config_app";
          let response = await axios.post(url, {
            token: this.token,
            channel: this.selectedChannel,
            show_categories: this.show_categories,
            show_branches: this.show_branches,
            show_links: this.show_links,
            show_ondemand: this.show_ondemand,
            show_volume: this.show_volume,
            save_m_variant: this.save_m_variant,
            smv_time: this.save_m_variant_count
          })
            .then(r => {
              console.log("set_radio_config_app", r.data);
              toastController.create({
                message: 'Configuración guardada correctamente',
                duration: 3000,
                color: 'success'
              }).then((toast) => {
                toast.present();
                this.isloading = false;
              });
              setTimeout(() => {
                toastController.create({
                  message: 'Los cambios se reflejarán en la aplicación en unos minutos.',
                  duration: 6000,
                  color: 'secondary',
                  position: 'top',
                  icon: "globe"
                }).then((toast) => {
                  toast.present();
                  this.isloading = false;
                });
              }, 1000);
            }).catch(error => {
              console.log("3Error en función validateToken de Conexiones: " + error.message);
              toastController.create({
                message: 'Error al guardar, favor de actualizar.',
                duration: 5000,
                color: 'danger'
              }).then((toast) => {
                toast.present();
              });
              this.isloading = false;
              return error;
            });
        }
      } catch (error) {
        console.log("Error en función saveConfig de Conexiones: " + error.message);
      }
    },

    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                //console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    },
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
      } catch (error) {
        //console.log("Error en función getStorageClientData de Conexiones: " + error.message);
      }
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        //console.log("Obteniendo token del storage...")
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          //console.log("Token obtenido: " + this.token);
          // console.log("Token obtenido:" + this.token)
          return this.validateToken(this.token);
        } else {
          //console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        //console.log("Error en función getStorageAuthToken de Conexiones: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        //console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              //console.log("r.data:");
              //console.log(r.data);
              return r;
            }).catch(error => {
              //console.log("3Error en función validateToken de Conexiones: " + error.message);
              return error;
            });
          //console.log("response.data:");
          //console.log(response.data);
          //console.log("response.data.success: " + response.data.success);
          //console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            //console.log("Error al validar token:");
            //console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            //console.log("Token válido");
            return true;
          } else {
            //console.log("Token inválido");
            //console.log("2response.data.success: " + response.data.success);
            //console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        //console.log("4Error en función validateToken de Conexiones: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          //console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    async getChannelList() {
      try {
        const ret = await Storage.get({ key: "cabina_admin_channel_list" });
        if (ret.value) {
          this.channel_list = JSON.parse(ret.value).slice();
          this.emitter.emit("channel_list", this.channel_list);
          this.channel_name_list = [];
          if (this.channel_list){
            this.channel_list.forEach(element => {
              this.channel_name_list.push({ id: element.id, name: element.name });
            });
            this.emitter.emit("channel_name_list", this.channel_name_list);
            //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
            //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
            if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
              const selectedChannelAux = await Storage.get({ key: "cabina_admin_selectedChannel" });
              //console.log("selectedChannelAux: " , selectedChannelAux);
              if (selectedChannelAux.value) {
                this.selectedChannel = selectedChannelAux.value;
                this.emitter.emit("selectedChannel", this.selectedChannel);
                Storage.set({
                  key: "cabina_admin_selectedChannel",
                  value: this.selectedChannel,
                });
              } else {
                this.selectedChannel = this.channel_list[0].id;
                this.loadChannelDashboard(this.selectedChannel);
                this.emitter.emit("selectedChannel", this.selectedChannel);
                Storage.set({
                  key: "cabina_admin_selectedChannel",
                  value: this.selectedChannel,
                });
              }
              //this.selectedChannel = this.channel_list[0].id;
              //this.loadChannelDashboard(this.selectedChannel);
              //this.emitter.emit("selectedChannel", this.selectedChannel);
            }
          }
        } else {
          console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - ", this.channel_list);
        }
      } catch (error) {
        console.log("Error en función cabina_admin_channel_list de Dashboard: ", error.message);
        return false;
      }
    }
  },
  mounted: function () {
    setTimeout(() => {

      try {
        if (Capacitor.getPlatform() == 'ios') {
          this.urlApi = "https://panel.vybroo.net/";
        } else {
          this.urlApi = "/api/";
        }

        //get auth token de storage y asignarlo a la variable token
        this.getStorageAuthToken();
        this.getStorageClientData('clientData');
        this.getChannelList();

        this.emitter.on("selectedChannel", (channel) => {
          //console.log("conexiones::::emitedON:selectedChannel: " , channel);
          this.selectedChannel = channel;
          this.getConfig();
        });
        this.emitter.on("channel_list", (channel_list) => {
          //console.log("conexiones::::emitedON:channel_list: " , channel_list);
          this.channel_list = channel_list;
        });
        this.emitter.on("channel_name_list", (channel_name_list) => {
          //console.log("conexiones::::emitedON:channel_name_list: " , channel_name_list);
          this.channel_name_list = channel_name_list;
        });
        this.emitter.on("dashboard", (dashboard) => {
          //console.log("conexiones::::emitedON:dashboard: " , dashboard);
          this.dashboard = dashboard;
        });

      } catch (error) {
        console.log("Error en función mounted de Conexiones: " + error.message);
      }


    }, 1000);
    setTimeout(() => {
      this.getConfig();
    }, 2000);
  },


})
</script>
<style lang="css">
.config_icon_question {
  margin-left: 8px;
  width: 15px;
  cursor: pointer;
  opacity: .8;
}

.toggle_config {
  right: 0;
  position: absolute;
}

.config_icon {
  margin-right: 10px;
}

.config_div {
  margin-top: 20px;
  padding: 20px;
  margin: auto;
  width: 100%;
  max-width: 500px;
}

.save_m_variant_count {
  width: 50px;
  max-width: 60px !important;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  padding: 3px !important;
  font-size: 20px;
}


/* PRETTY BUTTON ------------*/
.save_config_buton {
  width: 130px;
  height: 40px;
  color: #fff;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
  margin: 20px auto;
  display: block;
}
.sc-btn-3 {
  color: white;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(0deg, #2dd36f 0%, #2dd36f 100%);
  width: 180px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;

}
.sc-btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.sc-btn-3:before,
.sc-btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: #2dd36f;
  transition: all 0.3s ease;
}
.sc-btn-3:before {
  height: 0%;
  width: 2px;
}
.sc-btn-3:after {
  width: 0%;
  height: 2px;
}
.sc-btn-3:hover {
  background: transparent;
  color: #2dd36f;
  font-weight: bold;
  box-shadow: none;
}
.sc-btn-3:hover:before {
  height: 100%;
}
.sc-btn-3:hover:after {
  width: 100%;
}
.sc-btn-3 span:hover {
  color: #2dd36f;
}
.sc-btn-3 span:before,
.sc-btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #2dd36f;
  transition: all 0.3s ease;
}
.sc-btn-3 span:before {
  width: 2px;
  height: 0%;
}
.sc-btn-3 span:after {
  width: 0%;
  height: 2px;
}
.sc-btn-3 span:hover:before {
  height: 100%;
}
.sc-btn-3 span:hover:after {
  width: 100%;
}
/* PRETTY BUTTON ------------*/
</style>